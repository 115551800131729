import * as React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { GraphqlResults } from '../support-types'
import SEO from '../components/SEO'
import { makeStyles } from '@material-ui/core/styles'
import { Container, createStyles, Theme, Typography } from '@material-ui/core'
import BookGrid from '../components/book/BookGrid'
import NarrativeRow, { ImagePosition } from '../components/NarrativeRow'

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    bold: {
      fontWeight: 'bold',
    },
    center: {
      textAlign: 'center',
    },
    bookGrid: {
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      width: '90%',
      margin: 'auto',
      paddingTop: 20,
      display: 'flex',
    },
    promotedBooks: {
      marginTop: 50,
    },
  }),
)

const bookStackImage = (
  <StaticImage src='../images/bookStack.jpg' alt='stack of books' width={200} />
)

const balloonBookImage = (
  <StaticImage
    src='../images/balloonBook.jpg'
    alt='book with a balloon'
    width={200}
  />
)

const IndexPage = ({ data }: GraphqlResults) => {
  const classes = useStyles()
  const headingClasses = `${classes.center} ${classes.bold}`

  const books = data.books.nodes

  const PromotedBooks = () => (
    <div className={classes.promotedBooks}>
      <Typography variant='h4' className={headingClasses}>
        Some of our current favorites...
      </Typography>
      <Container className={classes.bookGrid}>
        <BookGrid books={books} />
      </Container>
    </div>
  )

  return (
    <div>
      <SEO title='Home' />

      <NarrativeRow image={bookStackImage} imagePosition={ImagePosition.LEFT}>
        Welcome to Off The KUF Publishing, Inc. We specialize in nonfiction
        children books that are beautifully illustrated, packed with information
        easily understood by children and educationally satisfying to parents.
        <p>Our books will open your child's eyes to the wonderful world of nature
        and the fascinating creatures who live in the wild from around the
           world.</p>
      </NarrativeRow>

      <NarrativeRow image={balloonBookImage} imagePosition={ImagePosition.RIGHT}>
        There are many children's books with talking animals and fantasy plots,
        but too few that are both entertaining and educational. Our children's
        books fill that need. We help parents teach their early readers with fun
        books exploring science and nature in an easy-to-understand format
        about some of the world's most amazing creatures.
      </NarrativeRow>

      <PromotedBooks />
    </div>
  )
}

export const query = graphql`
  query {
    books: allSanityBook(filter: { promote: { eq: true } }) {
      nodes {
        id
        slug {
          current
        }
        title
        shortDescription
        price
        frontCover {
          asset {
            gatsbyImageData(
              fit: FILLMAX
              width: 400
              height: 400
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`

export default IndexPage
